import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';


function NewsAdd() {

    const notificationId = useLocation().pathname.split("/")[3]

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAZbW7ZrKYxxeZhnyqRt9Bw0Dr3D207C0w"
    })

    const [notification, setNotification] = useState(null);
    const [user, setUser] = useState(null);
    const [denaiText, setDenaiText] = useState('');
    const HandleChange = (e) => {
        setDenaiText(e.target.value)
    }

    const fecthAllnotifications = async () => {
        try {
            let res = await axios.get(url + '/ProblemGet/' + notificationId, {
                headers: {
                  email: Cookies.get('admingromadia.in.email'),
                  password: Cookies.get('admingromadia.in.password')
                }
              })
            if(res.data['dataJs'][0].answer)
                setDenaiText(res.data['dataJs'][0].answer)
            setNotification(res.data['dataJs'][0])
            setUser(res.data['userJs'][0])
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fecthAllnotifications()
    }, [])


    const navigate = useNavigate();

    const handleDenai = async e => {
        if (denaiText.length > 5) {
            try {
                const res = await axios.put(url + "/ProblemSetAnswer/" + notificationId, { text: denaiText }, {
                    headers: {
                      email: Cookies.get('admingromadia.in.email'),
                      password: Cookies.get('admingromadia.in.password')
                    }
                  });
                navigate(-1)
            } catch (err) {
                console.log(err)
                alert('Error message: ' + err);
            }
        }
    }


    return (
        <div className='DataPage'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <button style={{ alignSelf: 'flex-end', height: '100%', marginLeft: 14 }} className='pluss' onClick={() => { navigate(-1) }}>X</button>
            </div>
            <div className='ImputWrapper'>Автор
                <p style={{ marginTop: 14, fontWeight: 400 }}>{user ?
                    <>{user.id}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {user.LastName} {user.Name} {user.Surname} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {user.Phone} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; м.{user.Gromada} вул.{user.Street}, {user.HouseNum}</>
                    : ''}</p>
            </div>
            <div className='ImputWrapper'>Назва
                <p style={{ marginTop: 14, fontWeight: 400 }}>{notification ? notification.title : ''}</p>
            </div>
            <div className='ImputWrapper'>Текст
                <p style={{ marginTop: 14, fontWeight: 400 }}>{notification ? notification.text : ''}</p>
            </div>
            {notification?.image.split(' ').length > 1 ?
                <div className='ImputWrapper'>Фото
                    <div className="ImageDiv">
                        {notification?.image.split(' ').slice(1).map((data) => (
                            <div key={data} className='ImageWrapper' style={{ fontWeight: 400, margin: 12, marginLeft: 24 }}>
                                <img src={url + '/imageGet/' + notification.image.split(' ')[0] + '_' + data} />
                            </div>
                        ))}
                    </div>
                </div> : <></>}

            {isLoaded && notification ? (
                <div className='ImputWrapper'>Локація
                    <GoogleMap
                        mapContainerStyle={{ width: '80%', height: 300 }}
                        center={{
                            lat: parseFloat(notification.location.split(' ')[0]),
                            lng:  parseFloat(notification.location.split(' ')[1])
                        }}
                        zoom={11}
                    >
                        <MarkerF position={{
                            lat: parseFloat(notification.location.split(' ')[0]),
                            lng:  parseFloat(notification.location.split(' ')[1])
                        }} />
                    </GoogleMap>
                </div>
            ) : <></>}
            <div className='ImputWrapper'>
                <textarea onChange={HandleChange} cols="40" rows="5" value={denaiText}></textarea>
                <div style={{ display: 'flex', justifyContent: 'row', alignItems: 'center' }}>
                    <button className='AddEvent' style={{ marginLeft: 0, backgroundColor: '#0F6C00' }} onClick={() => handleDenai()}>
                        <div style={{ textAlign: 'center', color: '#fff' }}>Зберегти</div>
                    </button>
                    {notification?.answer ? <p style={{ marginLeft: 34 }}>Увага! При редагуванні відповіді користувач отримає сповіщення!</p> : <></>}
                </div>
            </div>

        </div>
    )
}

export default NewsAdd
