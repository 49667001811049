import React, { useEffect, useState } from "react"
import './styles.css'

import axios from "axios";
import url from "../../url";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const WeekDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд']
const options = { weekday: 'short', month: 'long', day: 'numeric' };

function CNAPKalendar() {
  const [startTime, setStartTime] = useState(8)
  const [EndTime, setEndTime] = useState(18)

  const [dateCal, setdateCal] = useState(new Date())
  console.log(dateCal)
  const [events, setEvents] = useState([

  ])

  const fecthAllPodlugi = async (e) => {
    try {
      const res = await axios.get(url + '/AllCAnp_Online', {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      
      console.log(res.data)
      setEvents(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fecthAllPodlugi()
  }, [])

  //console.log(dateCal)
  function CheckTime(date, time) {
    var dataC = new Date(date)
    dataC.setHours(-24 * (-1));
    for (let value of events) {
      if (dataC.toISOString().split('T')[0] == value.date && time == value.time) {
        console.log(value)
        return value
      }
    }
    return null
  }

  function getMonday(date, index) {
    var dataC = new Date(date)
    var day = dataC.getDay() || 7;
    if (day !== 1)
      dataC.setHours(-24 * (day - 1));

    var tomorrow = dataC;
    tomorrow.setDate(tomorrow.getDate() + index);

    return tomorrow
  }

  function GetTimeList() {
    let res = []
    for (let i = startTime; i < EndTime; i++) {
      res.push(i + ':00')
      res.push(i + ':30')
    }
    return res
  }

  const handleDelete = async (id) => {
    try {
      await axios.put(url + '/CNAPOnlineWarn/' + id,{}, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      fecthAllPodlugi()
    } catch (err) {
      console.log(err)
    }
  }
  const navigate = useNavigate();
  function InfoBlock(e) {
    if (e)
      return (
        <>
          <div className="BlockInfo">
            №{e.id} {e.LastName} {e.Name} {e.Surname} <br /><br />{e.Phone}
            <br /><br /><br />
            {e.kategory}
            
              <button onClick={() => handleDelete(e.id)} className={e.warn && "warn"}>{e.warn ?" Громадянин не прийшов":"Громадянин не прийшов?"}</button>
          </div>

        </>
      )
    return
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className='NewsPodiiButton' style={{ backgroundColor: "#848484" }}>
          <button className='pollActiveButtons' onClick={() => {  navigate('/CNAP') }}>Адміністративні послуги
          </button>
        </div>
        <div className='NewsPodiiButton' style={{}}>
          <button className='pollActiveButtons' onClick={() => { }}>Електронна черга
          </button>
        </div>
      </div>
      <div className="CalendarHeader">
        <button onClick={() => { setdateCal(new Date()) }} className="Today"> Сьогодні </button>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <button onClick={() => { var tomorrow = dateCal; tomorrow.setDate(tomorrow.getDate() - 7); setdateCal(new Date(tomorrow)) }} className="arrowback"></button>
          <div className="CalendarHeaderBlock">
            {getMonday(dateCal, 0).toLocaleDateString('ua-UA', { day: 'numeric' }) + '-' + getMonday(dateCal, 6).toLocaleDateString('ua-UA', { day: 'numeric', month: 'long', year: 'numeric' })}
          </div>
          <button onClick={() => { var tomorrow = dateCal; tomorrow.setDate(tomorrow.getDate() + 7); setdateCal(new Date(tomorrow)) }} className="arrowback rotated"></button>
        </div>
        <button onClick={() => { var tomorrow = dateCal; tomorrow.setDate(tomorrow.getDate() + 7); setdateCal(new Date(tomorrow)) }} className="Today" disabled={true} style={{ opacity: 0 }}> Сьогодні</button>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            {WeekDays.map((day, index) => (
              <th className="CalTh" key={day}>{getMonday(dateCal, index).toLocaleDateString('ua-UA', options)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {GetTimeList().map((time, index1) => (
            <tr>
              <th key={index1}>{time + "-" + (parseInt(time.split(':')[1]) + 30 == 60 ? parseInt(time.split(':')[0]) + 1 : parseInt(time.split(':')[0])) + ":" + (parseInt(time.split(':')[1]) + 30 == 60 ? '00' : '30')}</th>
              {WeekDays.map((day, index2) => (
                <th className="CalTh" key={index2} style={getMonday(dateCal, index2).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0) ?
                  parseInt(time.split(':')[0]) == new Date().getHours() && parseInt(time.split(':')[1]) <= new Date().getMinutes() && parseInt(time.split(':')[1]) + 30 >= new Date().getMinutes() ? { backgroundColor: '#ace2de' } :
                    { backgroundColor: '#ace2de86' } :
                  {}}>
                  {InfoBlock(CheckTime(getMonday(dateCal, index2), time))}
                </th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>)
}
export default CNAPKalendar
