import axios from 'axios';
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
import {
  Editor,
  EditorProvider,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
  createButton,
} from 'react-simple-wysiwyg';


function NotificationsAdd() {
  const [notification, setNotification] = useState({
    title: "",
    text: "",
  });
  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();
  
  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.post(url + "/notifications", {
        title: notification.title,
        text: notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')
      }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      }
      );
      navigate('/Notifications')
    } catch (err) {

    }
  }

  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const inputRef = useRef(null);

  const wrapSelectedText = (tag) => {
    const input = inputRef.current;
    if (input.selectionStart === input.selectionEnd) {
      // No text selected
      return;
    }

    const start = input.selectionStart;
    const end = input.selectionEnd;
    const selectedText = input.value.substring(start, end);
    let wrappedText;
    if (tag === 'phone') {
      wrappedText = `<a href="tel:` + link + `">${selectedText}</a>`
      setLink('')
    } else if (tag === 'a') {
      wrappedText = `<${tag} href="` + link + `">${selectedText}</${tag}>`
      setLink('')
    } else if (tag === 'span') {
      wrappedText = `<${tag} style="">${selectedText}</${tag}>`
    } else {
      wrappedText = `<${tag}>${selectedText}</${tag}>`
    }

    // Replace the selected text with the wrapped text
    const newText = text.substring(0, start) + wrappedText + text.substring(end);
    setText(newText);

    // Move the cursor to the end of the wrapped text
    const newCursorPosition = start + wrappedText.length;
    input.focus();
    input.setSelectionRange(newCursorPosition, newCursorPosition);
  };


  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }

  const BtnAlignCenter = createButton('Link', '📞', ({ $selection }) => {
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink');
    } else {
      // eslint-disable-next-line no-alert
      document.execCommand('createLink', false, prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:') || undefined);
    }
  });

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>Заголовок
        <input onChange={HandleChange} name="title"></input>
      </div>
      <div className='ImputWrapper'>Текст сповіщення


        {/* <textarea onBlur={HandleChange} name="text" cols="40" rows="5"></textarea> */}
        {/* <div className='buttonTextWraper'>
          <button style={{ fontWeight: 800 }} onClick={() => wrapSelectedText('strong')}>Bold</button>
          <button style={{ fontStyle: "italic" }} onClick={() => wrapSelectedText('em')}>Italic</button>
          <button style={{ textDecoration: "Underline" }} onClick={() => wrapSelectedText('u')}>Underline</button>
          <button onClick={() => wrapSelectedText('span')}>Wrap with span</button>
          <button onClick={() => wrapSelectedText('a')}>Link</button>
          <button onClick={() => wrapSelectedText('phone')}>Phone</button>

          <input value={link} onChange={(e) => setLink(e.target.value)} style={{ width: 300, height: 24, minHeight: 0 }}></input>
        </div> */}
        {/* <textarea
          ref={inputRef}
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        /> */}
        <div className='Editor'>
          <EditorProvider>
            <Editor value={notification.text} onChange={onChange} >
              <Toolbar> 
                <BtnUndo />
                <BtnRedo />
                <Separator />
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnStrikeThrough />
                {/* <Separator /> */}
                {/* <BtnNumberedList />
                <BtnBulletList /> */}
                <Separator />
                <BtnLink />
                {/* <BtnClearFormatting /> */}
                
                <BtnAlignCenter />
                <Separator />
                <HtmlButton />
              </Toolbar>
            </Editor>
          </EditorProvider>
        </div>

        {/* <iframe className='HtmlWrapper' srcdoc={"<html><style>body {font-size: 18px;}</style><body >" + text.replaceAll('\n', '</br>') + "</body></html>"}></iframe> */}


      </div>
    </div>
  )
}

export default NotificationsAdd
