import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import url from '../../url';
import DatePicker from "react-datepicker";
import Cookies from 'js-cookie';
import {
    Editor,
    EditorProvider,
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    createButton,
} from 'react-simple-wysiwyg';

function NewsAdd() {

    const notificationId = useLocation().pathname.split("/")[3]

    const [startDate, setStartDate] = useState(new Date());
    const [allVotes, setAllVotes] = useState(0);
    const [paresedJson, setparesedJson] = useState([]);

    const [notification, setNotification] = useState({
        title: "",
        text: "",
        image: "",
        date: "",
        results: {}
    });
    const HandleChange = (e) => {
        setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const fecthAllnotifications = async () => {
        try {
            const res = await axios.get(url + '/pollGet/' + notificationId, {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })
            console.log(res.data)
            setparesedJson(JSON.parse(res.data[0].results))
            let count = 0;
            for (const items of JSON.parse(res.data[0].results)) {
                count += items.data.length
            }
            const dateArr = res.data[0].dateEnd.split('T')[0].split('-')
            setStartDate(new Date(dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0]))
            setAllVotes(count)
            setNotification(res.data[0])
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fecthAllnotifications()
    }, [])



    const navigate = useNavigate();

    const handleClick = async e => {
        e.preventDefault()
        if (1) {
            startDate.setTime(startDate.getTime() + 1000 * 60 * 12 * 60);
            console.log(startDate)
            notification.date = startDate.toISOString().substring(0, 10) + ' 23:59:59'
            console.log(notification.date)
            try {
                await axios.put(url + "/pollUpdate/" + notificationId, {...notification, text: notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')}, {
                    headers: {
                        email: Cookies.get('admingromadia.in.email'),
                        password: Cookies.get('admingromadia.in.password')
                    }
                });
                navigate(-1)
            } catch (err) {
                console.log(err)
                alert('Error message: ' + err);
            }
        } else {
            alert('Додайте хоча б одне фото!');
        }
    }



    function RemoveFromArray(arrayI, thisIndex) {
        const newArr = [];
        for (let i = 0; i < arrayI.length; i++) {
            if (i !== thisIndex)
                newArr.push(arrayI[i])
        }
        return newArr
    }

    function onChange(e) {
        setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
    }

    const BtnAlignCenter = createButton('Link', '📞', ({ $selection }) => {
        if ($selection?.nodeName === 'A') {
            document.execCommand('unlink');
        } else {
            // eslint-disable-next-line no-alert
            document.execCommand('createLink', false, prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:') || undefined);
        }
    });

    return (
        <div className='DataPage'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <button style={{ alignSelf: 'flex-end', height: '100%', marginLeft: 14 }} className='pluss' onClick={() => { navigate(-1) }}>X</button>
                <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
            </div>
            <div className='ImputWrapper'>Назва
                <input onChange={HandleChange} name="title" value={notification.title}></input>
            </div>
            <div className='ImputWrapper' style={{ width: 225 }}>Дата завершення
                <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => setStartDate(date)} />
            </div>
            <div className='ImputWrapper'>Текст
                {/* <textarea onChange={HandleChange} name="text" cols="40" rows="5" value={notification.text}></textarea> */}
                <div className='Editor'>
                    <EditorProvider>
                        <Editor value={notification.text} onChange={onChange} >
                            <Toolbar>
                                <BtnUndo />
                                <BtnRedo />
                                <Separator />
                                <BtnBold />
                                <BtnItalic />
                                <BtnUnderline />
                                <BtnStrikeThrough />
                                {/* <Separator /> */}
                                {/* <BtnNumberedList />
                <BtnBulletList /> */}
                                <Separator />
                                <BtnLink />
                                {/* <BtnClearFormatting /> */}

                                <BtnAlignCenter />
                                <Separator />
                                <HtmlButton />
                            </Toolbar>
                        </Editor>
                    </EditorProvider>
                </div>
            </div>

            <div className='ImputWrapper' >Варіанти відповіді
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {paresedJson.map((item, index) => (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <input className='disabledInput' disabled={true} style={{ width: 300 }} name={index} value={item.title}></input> {item.data.length !== 0 ? (item.data.length / allVotes * 100).toFixed(0) : 0}% - {item.data.length} голосів
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsAdd
