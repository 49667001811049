import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../../url';

function News() {
  const [notifications, setNotifications] = useState();

  const fecthAllnotifications = async () => {
    try {
      const res = await axios.get(url + '/news', {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      setNotifications(res.data.reverse())
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fecthAllnotifications()
  }, [])

  const handleDelete = async (id) => {
    try {
      await axios.delete(url + '/newsDelete/' + id, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      fecthAllnotifications()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent:'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='NewsPodiiButton'>Новини</div>    
          <Link to='/NewsPodii/Podii'>
            <div className='NewsPodiiButton' style={{backgroundColor:"#848484"}}>Події</div>
          </Link>
        </div>
        <Link to='/NewsPodii/NewsAdd'>
          <div className='AddEvent'>Додати новину</div>
        </Link>
      </div>
      <table >
        <thead>
          <tr>
            <th width="230">Дата публікації</th>
            <th>Заголовок</th>
            <th width="330">Дії</th>
          </tr>
        </thead>
        <tbody>
        {notifications?.map(notifications => (
            <tr key={notifications.id}>
              <th>{notifications.date.replaceAll('-', '.').split('T')[0]}</th>
              <th>{notifications.title}</th>
              <th>
                <div style={{display:"flex", paddingLeft:19}}>
                  {notifications.text !== 'air alarm start' && notifications.text !== 'air alarm end' ?
                    <Link to={`/NewsPodii/NewsUpdate/${notifications.id}`}>
                      <div className='notificationsButtons'>Редагувати</div>
                    </Link> : <div className='notificationsButtons' style={{backgroundColor:'#fff'}}></div>
                  }
                  <button className='notificationsButtons' style={{ backgroundColor: "#710000", color: '#fff' }} onClick={() => handleDelete(notifications.id)}>Видалити</button>
                </div>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default News
