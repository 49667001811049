import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../../url';

const Users = () => {
  const [users, setUser] = useState();
  useEffect(() => {
    const fecthAllUsers = async () => {
      try {
        const ver = await axios.get(url + '/getAllVerify', {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        setUser(ver.data)

      } catch (err) {
        console.log(err)
      }
    }
    fecthAllUsers()
  }, [])

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link to='/'>
            <div className='NewsPodiiButton' style={{ backgroundColor: "#848484" }}>Всі користувачі</div>
          </Link>
          <Link to='/'>
            <div className='NewsPodiiButton' style={{ backgroundColor: "#848484" }}>Верифіковані</div>
          </Link>
          <div className='NewsPodiiButton' style={{ backgroundColor: "#710000" }}>Запити верифікації</div>
        </div>
        {/* <Link to='/'>
          <div className='Download'>Завантажити в .xlsx</div>
        </Link> */}
      </div>

      <table >
        <thead>
          <tr>
            <th width="90">ID</th>
            <th>ПІБ</th>
            <th width="120">Дата народження</th>
            <th width="180">Номер телефону</th>
            <th>Місце проживання</th>
            <th>E-mail</th>
            <th width={160}>Варифікація</th>
          </tr>
        </thead>
        <tbody className='UsersTbody'>
          {users?.map(user => (
            <>
              {user.images.split(' ').length > 1 &&
                <tr key={user.id}>
                  <th>{user.id}</th>
                  <th>{user.LastName} {user.Name} {user.Surname}</th>
                  <th>{user.Birthday}</th>
                  <th>{user.Phone}</th>
                  <th>м.{user.Gromada}, вул.{user.Street}, {user.HouseNum}</th>
                  <th>{user.Email}</th>
                  <th>
                    <Link to={`/VerifyUpdate/${user.id}`} >
                      <div className='notificationsButtons' style={{ justifyContent: 'center', alignItems: 'center', marginLeft: 16 }}>Детальніше</div>
                    </Link>
                  </th>
                </tr>}
            </>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Users
