import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import url from '../../url';
import DatePicker from "react-datepicker";
import Cookies from 'js-cookie';
import {
    Editor,
    EditorProvider,
    BtnBold,
    BtnBulletList,
    BtnClearFormatting,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnRedo,
    BtnStrikeThrough,
    BtnStyles,
    BtnUnderline,
    BtnUndo,
    HtmlButton,
    Separator,
    Toolbar,
    createButton,
} from 'react-simple-wysiwyg';


function NewsAdd() {
    const [startDate, setStartDate] = useState(new Date());
    const [notification, setNotification] = useState({
        title: "",
        text: "",
        image: "",
        date: "",
        results: {}
    });
    const HandleChange = (e) => {
        setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const [buttons, setButtons] = useState(['']);
    const HandleChangeButton = (e) => {
        let arr = [...buttons]
        arr[parseInt(e.target.name)] = e.target.value
        setButtons(arr)
    }
    const addButton = (e) => {
        let arr = [...buttons]
        arr = arr.concat("");
        setButtons(arr)
    }

    const navigate = useNavigate();

    const handleClick = async e => {
        e.preventDefault()
        if (1) {
            for (let i = 0; i < selectedFile.length; i++)
                notification.image += ' ' + (i + 1)
            const obj = [];

            for (const key of buttons) {
                obj.push({ title: key, data: [] })
            }
            notification.results = JSON.stringify(obj, null, 2)
            notification.date = startDate.toISOString().substring(0, 10) + ' 23:59:59'
            //console.log(notification.date)
            try {
                const res = await axios.post(url + "/pollAdd", {...notification, text: notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')}, {
                    headers: {
                        email: Cookies.get('admingromadia.in.email'),
                        password: Cookies.get('admingromadia.in.password')
                    }
                });
                console.log(res.data)
                if (typeof res.data === "number")
                    UploadImages(res.data).then(navigate(-1));
            } catch (err) {
                console.log(err)
                alert('Error message: ' + err);
            }
        } else {
            alert('Додайте хоча б одне фото!');
        }
    }

    const UploadImages = async date => {
        //console.log(selectedFile)
        const formData = new FormData();
        for (let i = 0; i < selectedFile.length; i++) {
            //formData.append('files', selectedFile[i]);
            formData.append("files", selectedFile[i], date + "_" + (i + 1) + '.png')
        }
        //console.log(...formData)
        try {
            await axios.post(url + "/image", formData, {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            });
        } catch (err) {

        }
    }

    function onChange(e) {
        setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
    }

    const BtnAlignCenter = createButton('Link', '📞', ({ $selection }) => {
        if ($selection?.nodeName === 'A') {
            document.execCommand('unlink');
        } else {
            // eslint-disable-next-line no-alert
            document.execCommand('createLink', false, prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:') || undefined);
        }
    });


    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileUrl, setSelectedFileUrl] = useState([]);
    const changeHandler = (event) => {
        //console.log(...event.target.files)
        if (event.target.files.length > 0) {
            const urls = []
            for (let i = 0; i < event.target.files.length; i++) {
                urls[i] = URL.createObjectURL(event.target.files[i])
            }
            const NewFiles = [...selectedFile.concat(...event.target.files)]
            setSelectedFile(NewFiles);
            setSelectedFileUrl(selectedFileUrl.concat(urls))
        }
    };

    function RemoveFromArray(arrayI, thisIndex) {
        const newArr = [];
        for (let i = 0; i < arrayI.length; i++) {
            if (i !== thisIndex)
                newArr.push(arrayI[i])
        }
        return newArr
    }

    function RemoveFromArray(arrayI, thisIndex) {
        const newArr = [];
        for (let i = 0; i < arrayI.length; i++) {
            if (i !== thisIndex)
                newArr.push(arrayI[i])
        }
        return newArr
    }

    const Hendledelete = (event) => {
        const indexx = selectedFileUrl.indexOf(event.target.name);
        setSelectedFile(RemoveFromArray([...selectedFile], indexx));
        setSelectedFileUrl(RemoveFromArray(selectedFileUrl, indexx));
    };

    return (
        <div className='DataPage'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
                <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
            </div>
            <div className='ImputWrapper'>Назва
                <input onBlur={HandleChange} name="title"></input>
            </div>
            <div className='ImputWrapper' style={{ width: 225 }}>Дата завершення
                <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => setStartDate(date)} />
            </div>
            <div className='ImputWrapper'>Текст
                {/* <textarea onBlur={HandleChange} name="text" cols="40" rows="5"></textarea> */}
                <div className='Editor'>
                    <EditorProvider>
                        <Editor value={notification.text} onChange={onChange} >
                            <Toolbar>
                                <BtnUndo />
                                <BtnRedo />
                                <Separator />
                                <BtnBold />
                                <BtnItalic />
                                <BtnUnderline />
                                <BtnStrikeThrough />
                                {/* <Separator /> */}
                                {/* <BtnNumberedList />
                <BtnBulletList /> */}
                                <Separator />
                                <BtnLink />
                                {/* <BtnClearFormatting /> */}

                                <BtnAlignCenter />
                                <Separator />
                                <HtmlButton />
                            </Toolbar>
                        </Editor>
                    </EditorProvider>
                </div>
            </div>

            <div className='ImputWrapper' >Варіанти відповіді
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {buttons.map((button, index) => (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                <input style={{ width: 300 }} onChange={HandleChangeButton} name={index} value={buttons[index]}></input>
                                {buttons.length !== 1 ?
                                    <button style={{ alignSelf: 'flex-end' }} className='pluss' onClick={() => { setButtons(RemoveFromArray(buttons, index)) }}>-</button>
                                    : <button disabled={true} style={{ alignSelf: 'flex-end' }} className='pluss' onClick={() => { setButtons(RemoveFromArray(buttons, index)) }}>-</button>}
                            </div>
                        ))}
                    </div>

                    <button style={{ alignSelf: 'flex-end' }} className='pluss' onClick={() => { addButton() }}>+</button>
                </div>
            </div>

            <div className='ImputWrapper' style={{ marginBottom: 14 }}>Фото
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <label htmlFor="file-upload" className="custom-file-upload">
                    +
                </label>
                <input id="file-upload" type="file" name="file" onChange={changeHandler} multiple accept="image/png, image/gif, image/jpeg" />
                <div className="ImageDiv">
                    {selectedFileUrl?.map(file => (
                        <div key={file} className='ImageWrapper'>
                            <img src={file} alt=""></img>
                            <button onClick={Hendledelete} name={file} className='DeleteImg'>  </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default NewsAdd
