import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "../../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../../url';

function PollActive() {
  const [notifications, setNotifications] = useState();

  const [active, setActive] = useState(false);

  const navigate = useNavigate();
  const fecthAllnotifications = async () => {
    try {
      const res = await axios.get(url + '/allAdministrates', {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      console.log(res.data)
      setNotifications(res.data.reverse())
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fecthAllnotifications()
    if(url.includes(8803)){
      navigate('/CNAP/CNAPkontacts')
    }
  }, [])

  function getProblemsForTable() {
    let arr = []
    for (let i = 0; i < notifications.length; i++) {
      if (!active && !notifications[i].answer)
        arr.push(notifications[i])
      else if (active && notifications[i].answer)
        arr.push(notifications[i])
    }
    return arr
  }

  const handleDelete = async (id) => {
    try {
      await axios.delete(url + '/AdministratesDel/' + id, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      fecthAllnotifications()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='NewsPodiiButton' style={active === true ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { setActive(false) }}>Адміністративні послуги
            </button>
          </div>
          <div className='NewsPodiiButton' style={active === false ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { navigate('/CNAP/CNAPKalendar') }}>Електронна черга
            </button>
          </div>
          <div className='NewsPodiiButton' style={active === false ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { navigate('/CNAP/CNAPkontacts') }}>Контакти
            </button>
          </div>
        </div>
        <div style={{flexDirection:'row', display:'flex'}}>
          <Link to='/CNAP/CNAPKategories'>
            <div className='AddEvent' style={{ textAlign: 'center', backgroundColor: '#DCAC00' }}>Редагувати категорії</div>
          </Link>
          <Link to='/CNAP/CNAPAdd'>
            <div className='AddEvent' style={{ textAlign: 'center', backgroundColor: '#D9D9D9' }}>Додати послугу</div>
          </Link>
        </div>
      </div>
      <table >
        <thead>
          <tr>
            <th>Назва</th>
            <th width="180">Категорія</th>
            <th width="170">Дії</th>
          </tr>
        </thead>
        <tbody>
          {notifications && getProblemsForTable().map(notifications => (
            <tr key={notifications.id}>
              <th>{notifications.title}</th>
              <th>{notifications.kategory}</th>
              <th>
                <div style={{ display: "flex", paddingLeft: 19 }}>
                  <Link to={`/CNAP/CNAPdetails/${notifications.id}`}>
                    <div className='notificationsButtons'>Детальніше</div>
                  </Link>
                  <button className='notificationsButtons' style={{ backgroundColor: "#710000", color: '#fff' }} onClick={() => handleDelete(notifications.id)}>Видалити</button>
                </div>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PollActive
