import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../url';

const PasswordPage = () => {

  const [gromadaInfo, setGromadaInfo] = useState({
    count_votes: 0,
    days_for_voting: 0
  });
  const [saveText, setSaveText] = useState("Зберегти");
  const handleSave = async e => {
    e.preventDefault()
    try {
      await axios.post(url + "/gromada_info_params", gromadaInfo, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      }
      );
      setSaveText("Збережено")
    } catch (err) {

    }
  }

  const handleClick = (e) => {
    console.log(e.target.name)
    window.location.reload(false);
    try {
      Cookies.set('admingromadia.in.uaDatabase', 'https://gromadia.in.ua:' + e.target.name);
      //window.location.reload(false);
    } catch (err) {
      console.log(err)
    }
  }

  const fecthGromadaInfo = async () => {
    try {
      const res = await axios.get(url + '/gromada_days', {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })

      setGromadaInfo(res.data[0])


    } catch (err) {
      console.log(err)
    }
  }
  console.log(gromadaInfo)
  useEffect(() => {
    fecthGromadaInfo();
  }, [])

  const HandleChange = (e) => {
    setGromadaInfo(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8800"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Боярка</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8801"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Ірпінь</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8802"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Вишневе</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8803"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Гатне</button>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
            <button onClick={handleClick} name={"8804"} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Буча</button>
          </div>
        </div> */}
        <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
          <button onClick={handleSave} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>{saveText}</button>
        </div>
        <div className='ImputWrapper' style={{ flexDirection: "row", alignItems: 'center' }}>Необхідна кількість голосів
          <input onChange={HandleChange} type='number' name="count_votes" value={gromadaInfo?.count_votes} style={{ width: "auto", margin: 0, marginLeft: 24 }}></input>
        </div>
        <div className='ImputWrapper' style={{ flexDirection: "row", alignItems: 'center' }}>Кількість днів голосування
          <input onChange={HandleChange} type='number' name="days_for_voting" value={gromadaInfo?.days_for_voting} style={{ width: "auto", margin: 0, marginLeft: 24 }}></input>
        </div>
      </div>
    </div>
  )
}

export default PasswordPage
