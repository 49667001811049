import axios from 'axios';
import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import url from '../../url';
import DatePicker from "react-datepicker";
import Cookies from 'js-cookie';
function NewsAdd() {
    const [buttons, setButtons] = useState(['']);
  
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/problemKategoriesGet', {
            headers: {
              email: Cookies.get('admingromadia.in.email'),
              password: Cookies.get('admingromadia.in.password')
            }
          })
        setButtons(JSON.parse(res.data))
      } catch (err) {
        console.log(err)
      }
    }
    useEffect(() => {
      fecthAllnotifications()
    }, [])
  
    const handleClick = async e => {
        e.preventDefault()
        if (1) {
            let result = JSON.stringify(buttons, null, 2)

            try {
                const res = await axios.put(url + "/problemKategoriesUpdate", {result}, {
                    headers: {
                      email: Cookies.get('admingromadia.in.email'),
                      password: Cookies.get('admingromadia.in.password')
                    }
                  });
                navigate(-1)
            } catch (err) {
                console.log(err)
                alert('Error message: ' + err);
            }
        } else {
            alert('Додайте хоча б одне фото!');
        }
    }

    
    const HandleChangeButton = (e) => {
        let arr = [...buttons]
        arr[parseInt(e.target.name)] = e.target.value
        setButtons(arr)
    }
    const addButton = (e) => {
        let arr = [...buttons]
        arr = arr.concat("");
        setButtons(arr)
    }

    const navigate = useNavigate();


    function RemoveFromArray(arrayI, thisIndex) {
        const newArr = [];
        for (let i = 0; i < arrayI.length; i++) {
            if (i !== thisIndex)
                newArr.push(arrayI[i])
        }
        return newArr
    }

    function RemoveFromArray(arrayI, thisIndex) {
        const newArr = [];
        for (let i = 0; i < arrayI.length; i++) {
            if (i !== thisIndex)
                newArr.push(arrayI[i])
        }
        return newArr
    }

    return (
        <div className='DataPage'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent:'space-between' }}>
                <button className='arrowback' onClick={() => { navigate(- 1)}}></button>
                <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
            </div>


            <div className='ImputWrapper' >Категорії звернень
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {buttons.map((button, index) => (
                            <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                                <input style={{ width: 300 }} onChange={HandleChangeButton} name={index} value={buttons[index]}></input>
                                {buttons.length !== 1 ?
                                    <button style={{ alignSelf: 'flex-end' }} className='pluss' onClick={() => { setButtons(RemoveFromArray(buttons, index)) }}>-</button>
                                    : <button disabled={true} style={{ alignSelf: 'flex-end' }} className='pluss' onClick={() => { setButtons(RemoveFromArray(buttons, index)) }}>-</button>}
                            </div>
                        ))}
                    </div>

                    <button style={{ alignSelf: 'flex-end' }} className='pluss' onClick={() => { addButton() }}>+</button>
                </div>
            </div>
        </div>
    )
}

export default NewsAdd
