import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
function NewsAdd() {

    const notificationId = useLocation().pathname.split("/")[3]

    const [notification, setNotification] = useState(null);
    const [user, setUser] = useState(null);
    const [count, setCount] = useState(0);
    const [status, setStatus] = useState(false);
    const [denaiText, setDenaiText] = useState('');
    const [votedList, setVotedList] = useState('')
    const HandleChange = (e) => {
        setDenaiText(e.target.value)
    }

    const fecthAllnotifications = async () => {
        try {
            let res = await axios.get(url + '/petitionGet/' + notificationId, {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })
            console.log(res.data)
            if (res.data) {
                res.data['dataJs'][0].results = JSON.parse(res.data['dataJs'][0].results)
            }
            setNotification(res.data['dataJs'][0])
            setStatus(res.data['ended'])
            setUser(res.data['userJs'][0])
            setCount(res.data['count_votes'])
        } catch (err) {
            console.log(err)
        }
    }
    const getVotedList = async () => {
        try {
            const res = await axios.get(url + '/PetitionUsersList/' + notificationId, {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })
            let str = ''
            for (let item of res.data) {
                str += item.LastName + ' ' + item.Name + ' ' + item.Surname + ', '
            }
            console.log(str)
            setVotedList(str.slice(0, -2) + '.')
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fecthAllnotifications()
        getVotedList()
    }, [])
    const [days, setDays] = useState(30);
    const [votes, setVotes] = useState(30);
    const fetchDays = async () => {
        try {
            const res = await axios.get(url + '/gromada_days', {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })
            //console.log(res.data[0]['count_votes'])
            setDays(res.data[0]['days_for_voting'])
            setVotes(res.data[0]['count_votes'])
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchDays()
    }, [])
    function DateEndFORAnswer(datestart) {
        var date = new Date(datestart);
        date.setDate(date.getDate() + days + 7);
        return date.toISOString().replaceAll('-', '.').split('T')[0]
    }

    const navigate = useNavigate();

    const handleAllow = async e => {
        try {
            // await axios.put(url + "/petitionAllow/" + notificationId, {
            //     headers: {
            //       email: Cookies.get('admingromadia.in.email'),
            //       password: Cookies.get('admingromadia.in.password')
            //     }
            //   });
            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: url + "/petitionAllow/" + notificationId,
                headers: {
                  email: Cookies.get('admingromadia.in.email'),
                  password: Cookies.get('admingromadia.in.password')
                },
            };

            axios.request(config)
                .then((response) => {
                    navigate(-1)
                })
                .catch((error) => {
                    console.log(error);
                });
            

        } catch (err) {
            console.log(err)
            alert('Error message: ' + err);
        }
    }
    const handleDenai = async e => {
        if (denaiText.length > 5) {
            try {
                await axios.put(url + "/petitionDenai/" + notificationId, { text: denaiText }, {
                    headers: {
                        email: Cookies.get('admingromadia.in.email'),
                        password: Cookies.get('admingromadia.in.password')
                    }
                });
                navigate(-1)
            } catch (err) {
                console.log(err)
                alert('Error message: ' + err);
            }
        }
    }
    const handleAnswer = async e => {
        if (denaiText.length > 5) {
            try {
                await axios.put(url + "/petitionAnser/" + notificationId, { text: denaiText }, {
                    headers: {
                        email: Cookies.get('admingromadia.in.email'),
                        password: Cookies.get('admingromadia.in.password')
                    }
                });
                navigate(-1)
            } catch (err) {
                console.log(err)
                alert('Error message: ' + err);
            }
        }
    }


    return (
        <div className='DataPage'>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <button style={{ alignSelf: 'flex-end', height: '100%', marginLeft: 14 }} className='pluss' onClick={() => { navigate(-1) }}>X</button>
            </div>
            {notification?.status === "Denaid" ?
                <></> :
                <>{notification?.status ?
                    <div className='ImputWrapper' style={{ fontSize: 24 }}>
                        Голоси<br />
                        <p style={status ? notification.results.length < count ? { color: '#710000', fontWeight: 600 } : { color: '#0F6C00', fontWeight: 600 } : {}}>{notification.results.length}/{count}</p>
                        {status && notification.results.length >= count ?
                            <>
                                <span style={{ marginTop: 16, fontSize: 16 }}>Надати відповідь до {DateEndFORAnswer(notification.status)} </span>
                                <textarea onChange={HandleChange} cols="40" rows="5">{notification.answer}</textarea>
                                <button className='AddEvent' style={{ marginLeft: 0, backgroundColor: '#0F6C00' }} onClick={() => handleAnswer()}>
                                    <div style={{ textAlign: 'center', color: '#fff' }}>Відповісти</div>
                                </button>
                            </>
                            : <></>}
                    </div>
                    :
                    <></>}
                </>
            }
            <div className='ImputWrapper'>Автор
                <p style={{ marginTop: 14, fontWeight: 400 }}>{user ?
                    <>{user.id}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {user.LastName} {user.Name} {user.Surname} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {user.Phone} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; м.{user.Gromada} вул.{user.Street}, {user.HouseNum}</>
                    : ''}</p>
            </div>
            <div className='ImputWrapper'>Назва
                <p style={{ marginTop: 14, fontWeight: 400 }}>{notification ? notification.title : ''}</p>
            </div>
            <div className='ImputWrapper'>Текст
                <p style={{ marginTop: 14, fontWeight: 400 }}>{notification ? notification.text : ''}</p>
            </div>
            {notification?.image.split(' ').length > 1 ?
                <div className='ImputWrapper'>Фото
                    <div className="ImageDiv">
                        {notification?.image.split(' ').slice(1).map((data) => (
                            <div key={data} className='ImageWrapper' style={{ fontWeight: 400, margin: 12, marginLeft: 24 }}>
                                <img src={url + '/imageGet/' + notification.image.split(' ')[0] + '_' + data} />
                            </div>
                        ))}
                    </div>
                </div> : <></>}

            {notification?.status === "Denaid" ?
                <div className='ImputWrapper'>
                    <span style={{ color: '#850000' }}>Петиція відхилена</span>
                    <p style={{ fontWeight: 400, marginTop: 12 }}>{notification?.answer}</p>
                </div> :
                <>{notification?.status ? <></> :
                    <div className='ImputWrapper'>
                        <button className='AddEvent' style={{ marginBottom: 24, marginLeft: 0, backgroundColor: '#0F6C00' }} onClick={() => handleAllow()}>
                            <div style={{ textAlign: 'center', color: '#fff' }}>Допустити до голосування</div>
                        </button>
                        <textarea onChange={HandleChange} cols="40" rows="5"></textarea>
                        <div style={{ display: 'flex', justifyContent: 'row', alignItems: 'center' }}>
                            <button className='AddEvent' style={{ marginLeft: 0, backgroundColor: '#710000' }} onClick={() => handleDenai()}>
                                <div style={{ textAlign: 'center', color: '#fff' }}>Відхилити</div>
                            </button>
                            <p style={{ marginLeft: 34 }}>Щоб відхилити подання петиції адміністратор обов’язково повинен вказати причину!</p>
                        </div>
                    </div>}
                </>
            }
            {notification?.status === "Denaid" ?
                <></> :
                <>{notification?.status ?
                    <>
                        <div className='ImputWrapper'>
                            Проголосували<br />
                            <p style={{ fontWeight: 400 }}>{votedList}</p>
                        </div>
                    </>
                    :
                    <></>}
                </>
            }
        </div>
    )
}

export default NewsAdd
