import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
function NotificationsAdd() {
  let notificationId = useLocation().pathname.split("/")[2]

  //console.log(notificationId)

  const [notification,setNotification] = useState({
    title:"",
    text:"",
    coordinates:"",
  });

  const HandleChange = (e) =>{
    setNotification(prev => ({...prev,[e.target.name]: e.target.value}))
  }
  const navigate = useNavigate();

  const handleClick = async e =>{
    e.preventDefault()
    try{
      await axios.put(url + "/dailyAdd/"+notificationId, notification, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate(-1)
    }catch(err){
      console.log(err)
    }
  }
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
          <button onClick={handleClick} className='AddEvent' style={{backgroundColor:"#0F6C00", color:"#fff"}}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>Назва кнопки
        <input onBlur={HandleChange} name="title"></input>
      </div>
    </div>
  )
}

export default NotificationsAdd
