
import React, { useEffect, useState } from 'react'

import "../../styles/dataPage.css"

import {useNavigate, useLocation } from 'react-router-dom';

const Notifications = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/Social/SocialShow/r')
  }, [])
  return (
    <></>
  )
}

export default Notifications
