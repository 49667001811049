import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../url';

const Users = () => {
  const [users, setUser] = useState();

  const [onlyVer, setOnlyVer] = useState(false);

  useEffect(() => {
    const fecthAllUsers = async () => {
      try {
        const res = await axios.get(url + '/users', {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        console.log(res.data)
        setUser(res.data)
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllUsers()
  }, [])




  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button className='NewsPodiiButton' style={{ backgroundColor: onlyVer? "#848484" : "#2D2D2D" }} onClick={()=>{setOnlyVer(false);}}>Всі користувачі</button>
          <button className='NewsPodiiButton' style={{ backgroundColor: !onlyVer? "#848484" : "#2D2D2D" }} onClick={()=>{setOnlyVer(true);}}>Верифіковані</button>
          <Link to='/VerifyList'>
            <div className='NewsPodiiButton' style={{ backgroundColor: "#710000" }}>Запити верифікації</div>
          </Link>
        </div>
        {/* <Link to='/'>
          <div className='Download'>Завантажити в .xlsx</div>
        </Link> */}
      </div>

      <table >
        <thead>
          <tr>
            <th width="90">ID</th>
            <th>ПІБ</th>
            <th width="120">Дата народження</th>
            <th width="180">Номер телефону</th>
            <th>Місце проживання</th>
            <th>E-mail</th>
            <th>Варифікація</th>
          </tr>
        </thead>
        <tbody className='UsersTbody'>
          {users?.map(user => (
            <>
              {((user.verificate == 2) || (!onlyVer)) &&
                <tr key={user.id}>
                  <th>{user.id}</th>
                  <th>{user.LastName} {user.Name} {user.Surname}</th>
                  <th>{user.Birthday}</th>
                  <th>{user.Phone}</th>
                  <th>м.{user.Gromada}, вул.{user.Street}, {user.HouseNum}</th>
                  <th>{user.Email}</th>
                  <th style={{ color: user.verificate == 2 ? "#0F6C00" : user.verificate == 1 ? "#DCAC00" : "#710000", fontWeight: 600 }}>{user.verificate == 2 ? "Верифікований" : user.verificate == 1 ? "Верифікований" : "Не Верифікований"}</th>
                </tr>
              }
            </>
          ))} 
        </tbody>
      </table>
    </div>
  )
}

export default Users
