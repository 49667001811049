
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';

function NotificationsUpdate() {

  const notificationId = useLocation().pathname.split("/")[2]
  const [vake, setVake] = useState('');
  const [notification, setNotification] = useState({
    Birthday: "",
    Date: "",
    Email: "",
    Gromada: "",
    HouseNum: "",
    LastName: "",
    Name: "",
    Phone: "",
    Street: "",
    Surname: "",
    images: "",
    phone: ""
  });

  const [imageUrl1, setImageUrl1] = useState(null);
  const [imageUrl2, setImageUrl2] = useState(null);

  useEffect(() => {
    const headers = {
      email: Cookies.get('admingromadia.in.email'),
      password: Cookies.get('admingromadia.in.password')
    };

    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/getVerifyByID/' + notificationId, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })

        setNotification(res.data[0])

        if (res.data[0].images.includes(" 1")) {
          try {
            const response = await axios.get(url + '/imageGetPrivate/' + res.data[0].images.split(' ')[0] + '_1', {
              headers: headers,
              responseType: 'blob',
            });

            const imageBlob = new Blob([response.data], { type: 'image/jpeg' }); // Adjust the type as needed
            setImageUrl1(URL.createObjectURL(imageBlob));
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        }
        if (res.data[0].images.includes(" 2")) {
          try {
            const response = await axios.get(url + '/imageGetPrivate/' + res.data[0].images.split(' ')[0] + '_2', {
              headers: headers,
              responseType: 'blob',
            });

            const imageBlob = new Blob([response.data], { type: 'image/jpeg' }); // Adjust the type as needed
            setImageUrl2(URL.createObjectURL(imageBlob));
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        }

      } catch (err) {
        console.log(err)
      }


    }


    fecthAllnotifications()


  }, [vake])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleDenie = async e => {
    e.preventDefault()
    try {
      const res = await axios.post(url + "/deniePasport/" + notificationId, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      setVake('1')

    } catch (err) {

    }
  }

  const handleAllow = async e => {
    e.preventDefault()
    try {
      const res = await axios.post(url + "/allowPasport/" + notificationId, notification, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      setVake('2')
      //navigate('/Notifications')
    } catch (err) {

    }
  }

  const handleDenieVerPhoto = async e => {
    e.preventDefault()
    try {
      const res = await axios.post(url + "/denieResPhoto/" + notificationId, notification, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      if (res.data == "Not Valid Pasport") {
        alert("Спочатку підтвердіть паспорт!")
      } else {
        setVake('3')
      }
      //navigate('/Notifications')
    } catch (err) {

    }
  }
  const handleDenieVerRes = async e => {
    e.preventDefault()
    try {
      const res = await axios.post(url + "/denieResRes/" + notificationId, notification, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      if (res.data == "Not Valid Pasport") {
        alert("Спочатку підтвердіть паспорт!")
      } else {
        setVake('4')
      }
      //navigate('/Notifications')
    } catch (err) {

    }
  }
  const AllowRes = async e => {
    e.preventDefault()
    try {
      const res = await axios.post(url + "/allowRes/" + notificationId, notification, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      if (res.data == "Not Valid Pasport") {
        alert("Спочатку підтвердіть паспорт!")
      } else {
        setVake(null)
      }
      //navigate('/Notifications')
    } catch (err) {

    }
  }



  return (
    <div className='DataPage'>
      {notification.images.split(' ').includes("1") && <div style={{ flexDirection: 'row', display: 'flex', marginTop: 32 }}>
        <div className='ImputWrapper' style={{ width: '30%' }}>
          <p>Прізвище</p>
          <input onChange={HandleChange} name="LastName" value={notification?.LastName}></input>
          <p style={{ marginTop: 12 }}>Ім’я</p>
          <input onChange={HandleChange} name="Name" value={notification?.Name}></input>
          <p style={{ marginTop: 12 }}>По-батькові</p>
          <input onChange={HandleChange} name="Surname" value={notification?.Surname}></input>
          <p style={{ marginTop: 12 }}>Дата народження</p>
          <input onChange={HandleChange} name="Birthday" value={notification?.Birthday}></input>
        </div>
        {notification.images && <img style={{ width: 500, objectFit: 'contain' }} src={imageUrl1} />}
        <div className='ImputWrapper' style={{ width: '30%', justifyContent: 'end' }}>
          <button onClick={handleDenie} className='AddEvent' style={{ backgroundColor: "#848484", color: "#fff", width: '100%' }}>Відхилено<br />(некоретне фото)</button>
          <button onClick={handleAllow} className='AddEvent' style={{ backgroundColor: "#2D2D2D", color: "#fff", width: '100%' }}>Перевірено та виправлено<br />(за потреби)</button>
        </div>
      </div>}
      {notification.images.split(' ').includes("2") && <div style={{ flexDirection: 'row', display: 'flex', marginTop: 32 }}>
        <div className='ImputWrapper' style={{ width: '30%' }}>
          {/* <p style={{ marginTop: 12 }}>Населений пункт</p>
          <input onChange={HandleChange} name="Gromada" value={notification?.Gromada}></input>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between' }}>
            <div style={{ width:'40%'}}>
              <p style={{ marginTop: 12 }}>Вулиця</p>
              <input onChange={HandleChange} name="Street" value={notification?.Street}></input>
            </div>
            <div style={{ width:'40%'}}>
              <p style={{ marginTop: 12 }}>№</p>
              <input onChange={HandleChange} name="HouseNum" value={notification?.HouseNum}></input>
            </div>
          </div> */}
        </div>
        {notification.images && <img style={{ width: 500, objectFit: 'contain' }} src={imageUrl2} />}
        <div className='ImputWrapper' style={{ width: '30%', justifyContent: 'end' }}>
          <div style={{ height: '60%', backgroundColor: "#000", width: 16, margin: "0 auto" }}></div>
          <button onClick={handleDenieVerPhoto} className='AddEvent' style={{ backgroundColor: "#848484", color: "#fff", width: '100%' }}>Відхилено<br />(некоретне фото)</button>
          <button onClick={handleDenieVerRes} className='AddEvent' style={{ backgroundColor: "#848484", color: "#fff", width: '100%' }}>Резиденство не підтверджено</button>
          <button onClick={AllowRes} className='AddEvent' style={{ backgroundColor: "#2D2D2D", color: "#fff", width: '100%' }}>Перевірено</button>
        </div>
      </div>}
    </div>
  )
}

export default NotificationsUpdate
