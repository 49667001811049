import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
function NewsAdd() {
  let notificationId = useLocation().pathname.split("/")[2]

  const [notification, setNotification] = useState({
    image: "",
  });

  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()

    try {
      // const res = await axios.delete(url + "/dailyDeleteImage/" + notificationId, notification, {
      //   headers: {
      //     email: Cookies.get('admingromadia.in.email'),
      //     password: Cookies.get('admingromadia.in.password')
      //   }
      // });
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: url + "/dailyDeleteImage/" + notificationId,
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        },
        data : notification
    };

    axios.request(config)
        .then((response) => {
            navigate(-1)
        })
        .catch((error) => {
            console.log(error);
        });
      handleAdd()
    } catch (err) {
      console.log(err)
      alert('Error message: ' + err);
    }

  }

  const handleAdd = async e => {
    for (let i = 0; i < selectedFile.length; i++)
      notification.image += ' ' + (i + 1)
    //console.log(notification)
    try {
      const res = await axios.put(url + "/dailyAddImage/" + notificationId, notification, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      if (typeof res.data === "number")
        UploadImages(res.data).then(navigate(-1));
    } catch (err) {
      console.log(err)
      alert('Error message: ' + err);
    }

  }

  const UploadImages = async date => {
    //console.log(selectedFile)
    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      //formData.append('files', selectedFile[i]);
      formData.append("files", selectedFile[i], date + "_" + (i + 1) + '.png')
    }
    //console.log(...formData)
    try {
      await axios.post(url + "/image", formData, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
    } catch (err) {

    }
  }




  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState([]);
  const changeHandler = (event) => {
    //console.log(...event.target.files)
    if (event.target.files.length > 0) {
      const urls = []
      for (let i = 0; i < event.target.files.length; i++) {
        urls[i] = URL.createObjectURL(event.target.files[i])
      }
      const NewFiles = [...selectedFile.concat(...event.target.files)]
      setSelectedFile(NewFiles);
      setSelectedFileUrl(selectedFileUrl.concat(urls))
    }
  };

  function RemoveFromArray(arrayI, thisIndex) {
    const newArr = [];
    for (let i = 0; i < arrayI.length; i++) {
      if (i !== thisIndex)
        newArr.push(arrayI[i])
    }
    return newArr
  }

  const Hendledelete = (event) => {
    const indexx = selectedFileUrl.indexOf(event.target.name);
    setSelectedFile(RemoveFromArray([...selectedFile], indexx));
    setSelectedFileUrl(RemoveFromArray(selectedFileUrl, indexx));
  };

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
      </div>
      <div className='ImputWrapper' style={{ marginBottom: 14 }}>Фото
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <label htmlFor="file-upload" className="custom-file-upload">
          +
        </label>
        <input id="file-upload" type="file" name="file" onChange={changeHandler} multiple accept="image/png, image/gif, image/jpeg" />
        <div className="ImageDiv">
          {selectedFileUrl?.map(file => (
            <div key={file} className='ImageWrapper'>
              <img src={file} alt=""></img>
              <button onClick={Hendledelete} name={file} className='DeleteImg'>  </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NewsAdd
