import Cookies from 'js-cookie';


function getUrl(){
    if(Cookies.get('admingromadia.in.uaDatabase')){
        return Cookies.get('admingromadia.in.uaDatabase')}
    else{
        Cookies.set('admingromadia.in.uaDatabase', 'https://gromadia.in.ua:8800');
        return "https://gromadia.in.ua:8800"
    }
}

const url = getUrl();

//"https://gromadia.in.ua:8800"
//http://localhost:8800
//http://176.114.3.98:8800

export default url
