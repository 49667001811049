import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../../url';

function PollActive() {
  const [notifications, setNotifications] = useState();

  const [active, setActive] = useState('/pollsActive');

  const fecthAllnotifications = async () => {
    try {
      const res = await axios.get(url + active, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      setNotifications(res.data.reverse())
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fecthAllnotifications()
  }, [active])

  const handleDelete = async (id) => {
    try {
      await axios.delete(url + '/pollDelete/' + id, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      fecthAllnotifications()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='NewsPodiiButton' style={active !== '/pollsActive' ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { setActive('/pollsActive') }}>Активні
            </button>
          </div>
          <div className='NewsPodiiButton' style={active !== '/pollsDisabled' ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { setActive('/pollsDisabled') }}>Завершені
            </button>
          </div>
        </div>
        {active !== '/pollsDisabled' ?
          <Link to='/Poll/PollAdd'>
            <div className='AddEvent' style={{ textAlign: 'center' }}>Додати опитування</div>
          </Link>
          : null}
      </div>
      <table >
        <thead>
          <tr>
            <th>Назва</th>
            <th width="180">Дата початку</th>
            <th width="180">Дата завершення</th>
            <th width="330">Дії</th>
          </tr>
        </thead>
        <tbody>
          {notifications?.map(notifications => (
            <tr key={notifications.id}>
              <th>{notifications.title}</th>
              <th>{notifications.date.replaceAll('-', '.').split('T')[0]}</th>
              <th>{notifications.dateEnd.replaceAll('-', '.').split('T')[0]}</th>
              <th>
                <div style={{ display: "flex", paddingLeft: 19 }}>
                  <Link to={`/Poll/PollImage/${notifications.id}`}>
                    <div  style={{ backgroundColor: '#D9D9D9' }} className='notificationsButtons'>Замінити фото</div>
                  </Link>
                  <Link to={`/Poll/PollDetails/${notifications.id}`}>
                    <div className='notificationsButtons'>Детальніше</div>
                  </Link>
                  <button className='notificationsButtons' style={{ backgroundColor: "#710000", color: '#fff' }} onClick={() => handleDelete(notifications.id)}>Видалити</button>
                </div>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PollActive
