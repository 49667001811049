
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';
import {
  Editor,
  EditorProvider,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
  createButton,
} from 'react-simple-wysiwyg';


function NotificationsUpdate() {

  const notificationId = useLocation().pathname.split("/")[3]

  const [notification, setNotification] = useState({
    title: "",
    text: "",
    kategory: ""
  });

  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/Administrate/' + notificationId, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        setNotification(res.data[0])
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();


  const [buttons, setButtons] = useState(['']);
  useEffect(() => {
    fecthAllnotifications()
  }, [])

  const fecthAllnotifications = async () => {
    try {
      const res = await axios.get(url + '/CNAPKategoriesGet', {headers:{email: Cookies.get('admingromadia.in.email'), password: Cookies.get('admingromadia.in.password')}})
      setButtons(JSON.parse(res.data))
    } catch (err) {
      console.log(err)
    }
  }

  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.put(url + "/AddAdministratesUpdate/" + notificationId, {
        ...notification,
        text: notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')
      }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate(-1)
    } catch (err) {

    }
  }

  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }

  const BtnAlignCenter = createButton('Link', '📞', ({ $selection }) => {
    if ($selection?.nodeName === 'A') {
      document.execCommand('unlink');
    } else {
      // eslint-disable-next-line no-alert
      document.execCommand('createLink', false, prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:') || undefined);
    }
  });

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Оновити</button>
      </div>

      <div className='ImputWrapper'>Заголовок
        <input onChange={HandleChange} name="title" value={notification?.title}></input>

      </div>
      <div className='ImputWrapper' >Категорії звернень
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              {buttons.map((button, index) => (
                <button value={button}  name="kategory" onClick={HandleChange} key={index} style={notification.kategory == button? {backgroundColor:'#0F6C00',color: "#fff"}:{}} className='AddEvent' >{button}</button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='Editor'> Текст послуги
        <EditorProvider>
          <Editor value={notification.text} onChange={onChange} >
            <Toolbar>
              <BtnUndo />
              <BtnRedo />
              <Separator />
              <BtnBold />
              <BtnItalic />
              <BtnUnderline />
              <BtnStrikeThrough />
              {/* <Separator /> */}
              {/* <BtnNumberedList />
                <BtnBulletList /> */}
              <Separator />
              <BtnLink />
              {/* <BtnClearFormatting /> */}

              <BtnAlignCenter />
              <Separator />
              <HtmlButton />
            </Toolbar>
          </Editor>
        </EditorProvider>
      </div>
    </div>
  )
}

export default NotificationsUpdate
