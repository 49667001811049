import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../../styles/dataPage.css"
import Cookies from 'js-cookie';
import url from '../../url';

function PollActive() {
  const [notifications, setNotifications] = useState();

  const [active, setActive] = useState('new');
  const [petition, setPetition] = useState({})
  const [petitionEnded, setPetitionEnded] = useState({})

  const fecthAllnotifications = async () => {
    try {
      const res = await axios.get(url + '/getpetitions', {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      setNotifications(res.data.reverse())
    } catch (err) {
      console.log(err)
    }
  }
  const notActive = async () => {
    try {
        const res = await axios.get(url + '/getpetitionsActive', {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        if(res.data){
            for(let i = 0; i < res.data.length; i++){
            let new_res = res.data[i]
            new_res.results = JSON.parse(new_res.results)
            }
        }
        setPetition(res.data.reverse())
    } catch (err) {
        console.log(err)
    }
}
const notEnded = async () => {
  try {
      const res = await axios.get(url + '/getpetitionsDisabled', {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      if(res.data){
          for(let i = 0; i < res.data.length; i++){
          let new_res = res.data[i]
          new_res.results = JSON.parse(new_res.results)
          }
      }
      setPetitionEnded(res.data.reverse())
  } catch (err) {
      console.log(err)
  }
}
  useEffect(() => {
    fecthAllnotifications()
    notActive()
    notEnded()
  }, [])

  const handleDelete = async (id) => {
    try {
      await axios.delete(url+"/petitionDelete/"  + id, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      })
      fecthAllnotifications()
    } catch (err) {
      console.log(err)
    }
  }

  function MyList() {
    let newArr = []
    if (notifications)
      for (let value of notifications) {
        if ((active === 'new' && (value.status === null || value.status === 'Denaid')))
          newArr.push(value)
        if ((active === 'active'))
          newArr = petition
        if ((active === 'ended'))
          newArr = petitionEnded
      }
    //console.log(newArr)
    return newArr
  }

  const [days, setDays] = useState(30);
  const [votes, setVotes] = useState(30);
  const fetchDays = async () => {
      try {
          const res = await axios.get(url + '/gromada_days', {
            headers: {
              email: Cookies.get('admingromadia.in.email'),
              password: Cookies.get('admingromadia.in.password')
            }
          })
          //console.log(res.data[0]['count_votes'])
          setDays(res.data[0]['days_for_voting'])
          setVotes(res.data[0]['count_votes'])
      } catch (err) {
          console.log(err)
      }
  }
  useEffect(() => {
      fetchDays()
  }, [])

  function DateEnd(datestart){
    var date = new Date(datestart);
    date.setDate(date.getDate() + days);
    return date.toISOString().replaceAll('-', '.').split('T')[0]
  }

  function DateEndFORAnswer(datestart){
    var date = new Date(datestart);
    date.setDate(date.getDate() + days + 7);
    return date.toISOString().replaceAll('-', '.').split('T')[0]
  }

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='NewsPodiiButton' style={active !== 'new' ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { setActive('new') }}>Нові
            </button>
          </div>
          <div className='NewsPodiiButton' style={active !== 'active' ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { setActive('active') }}>Активні
            </button>
          </div>
          <div className='NewsPodiiButton' style={active !== 'ended' ? { backgroundColor: "#848484" } : null}>
            <button className='pollActiveButtons' onClick={() => { setActive('ended') }}>Завершені
            </button>
          </div>
        </div>
      </div>
      <table >
        <thead>
          <tr>
            <th>Назва</th>
            {active === 'new' && <>
              <th width="180">Дата подачі</th>
              <th width="180">Статус</th>
            </>
            }
            {active === 'active' && <>
              <th width="180">Дата початку</th>
              <th width="180">Дата завершення</th>
            </>
            }
            {active === 'ended' && <>
              <th width="180">Дата завершення</th>
              <th width="180">Статус</th>
            </>
            }
            <th width="330">Дії</th>
          </tr>
        </thead>
        <tbody>
          {notifications ? MyList().map(notification => (
            <tr key={notification.id}>
              <th>{notification.title}</th>
              {active === 'new' &&
                <>
                  <th>{notification.date.replaceAll('-', '.').split('T')[0]}</th>
                  {notification.status == null ?
                    <th style={{ color: '#0F6C00', fontWeight: 600 }}> Нова</th>
                    :
                    <th style={{ color: '#710000', fontWeight: 600 }}>Відхилена</th>

                  }</>}
              {active == 'active' &&
                <>
                  <th>{notification.status.replaceAll('-', '.').split(' ')[0]}</th>
                  <th>{DateEnd(notification.status)}</th>
                </>}
                {active == 'ended' &&
                <>
                  <th>{DateEnd(notification.status)}</th>
                  {notification.answer?<th style={{fontWeight:600}}>Завершено</th>:
                  <th style={notification.results.length < votes? {color:'#710000', fontWeight:600}:{color:'#0F6C00', fontWeight:600}}>{notification.results.length < votes? 'Недостатньо голосів':'Відповісти до '+  DateEndFORAnswer(notification.status)}</th>
                  }
                  </>}
              <th>
                <div style={{ display: "flex", paddingLeft: 19 }}>
                  <Link to={`/Petition/PetitionDetails/${notification.id}`}>
                    <div className='notificationsButtons'>Детальніше</div>
                  </Link>
                  <button className='notificationsButtons' style={{ backgroundColor: "#710000", color: '#fff' }} onClick={() => handleDelete(notification.id)}>Видалити</button>
                </div>
              </th>
            </tr>
          )) : <></>}
        </tbody>
      </table>
    </div>
  )
}

export default PollActive
